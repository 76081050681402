import { combineReducers } from 'redux';
import at from '../types';

export const profile = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return action.data;
    }
    case at.UPDATE_SUCCESS: {
      return { ...state, ...action.userProfile };
    }
    case at.UPDATE_USERNAME_SUCCESS: {
      return { ...state, ...{ username: action.username.toUpperCase() } };
    }
    default:
      return state;
  }
};

export const emailSettings = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_EMAIL_SETTINGS_SUCCESS: {
      return action.data;
    }
    case at.UPDATE_EMAIL_SETTINGS_SUCCESS: {
      return { ...state, ...action.emailSettings };
    }
    default:
      return state;
  }
};

export const fetchStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_REQUEST: {
      return 'loading';
    }
    case at.FETCH_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const emailSettingsStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_EMAIL_SETTINGS_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_EMAIL_SETTINGS_REQUEST: {
      return 'loading';
    }
    case at.FETCH_EMAIL_SETTINGS_FAILURE: {
      return 'failed';
    }
    case at.RESET_UPDATE_STATUS: {
      return '';
    }
    default:
      return state;
  }
};

export const updateStatus = (state = '', action) => {
  switch (action.type) {
    case at.UPDATE_SUCCESS: {
      return 'loaded';
    }
    case at.UPDATE_REQUEST: {
      return 'loading';
    }
    case at.UPDATE_FAILURE: {
      return 'failed';
    }
    case at.RESET_UPDATE_STATUS: {
      return '';
    }
    default:
      return state;
  }
};

export const updateUsernameStatus = (state = '', action) => {
  switch (action.type) {
    case at.UPDATE_USERNAME_SUCCESS: {
      return 'loaded';
    }
    case at.UPDATE_USERNAME_REQUEST: {
      return 'loading';
    }
    case at.UPDATE_USERNAME_FAILURE: {
      return 'failed';
    }
    case at.RESET_UPDATE_USERNAME_STATUS: {
      return '';
    }
    default:
      return state;
  }
};

export const updateErrorMessage = (state = '', action) => {
  switch (action.type) {
    case at.UPDATE_USERNAME_FAILURE: {
      return action.error.message;
    }
    default:
      return state;
  }
};

export const updatePasswordStatus = (state = '', action) => {
  switch (action.type) {
    case at.UPDATE_PASSWORD_SUCCESS: {
      return 'loaded';
    }
    case at.UPDATE_PASSWORD_REQUEST: {
      return 'loading';
    }
    case at.UPDATE_PASSWORD_FAILURE: {
      return 'failed';
    }
    case at.RESET_UPDATE_PASSWORD_STATUS: {
      return '';
    }
    default:
      return state;
  }
};

export const updateEmailSettingsStatus = (state = '', action) => {
  switch (action.type) {
    case at.UPDATE_EMAIL_SETTINGS_SUCCESS: {
      return 'loaded';
    }
    case at.UPDATE_EMAIL_SETTINGS_REQUEST: {
      return 'loading';
    }
    case at.UPDATE_EMAIL_SETTINGS_FAILURE: {
      return 'failed';
    }
    case at.RESET_UPDATE_EMAIL_SETTINGS_STATUS: {
      return '';
    }
    default:
      return state;
  }
};

export const conciergeManager = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_CONCIERGE_MANAGER_SUCCESS: {
      const { conciergeManager } = action.data;
      return conciergeManager;
    }
    default:
      return state;
  }
};

export const conciergeManagerStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_CONCIERGE_MANAGER_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_CONCIERGE_MANAGER_REQUEST: {
      return 'loading';
    }
    case at.FETCH_CONCIERGE_MANAGER_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const communicationSettings = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_COMMUNICATION_SETTINGS_SUCCESS: {
      const { communicationSettings } = action.data;
      return communicationSettings;
    }
    case at.UPDATE_COMMUNICATION_SETTINGS_SUCCESS: {
      const { communicationSettings } = action;
      if (!communicationSettings.phone) {
        communicationSettings.phone = state.phone;
        communicationSettings.phoneExt = state.phoneExt;
      }
      return { ...state, ...communicationSettings };
    }
    default:
      return state;
  }
};

export const communicationSettingsStatus = (state = '', action) => {
  switch (action.type) {
    case at.FETCH_COMMUNICATION_SETTINGS_SUCCESS: {
      return 'loaded';
    }
    case at.FETCH_COMMUNICATION_SETTINGS_REQUEST: {
      return 'loading';
    }
    case at.FETCH_COMMUNICATION_SETTINGS_FAILURE: {
      return 'failed';
    }
    case at.UPDATE_COMMUNICATION_SETTINGS_REQUEST: {
      return 'loading-update';
    }
    case at.UPDATE_COMMUNICATION_SETTINGS_SUCCESS: {
      return 'reload';
    }
    default:
      return state;
  }
};

export const updateCommunicationSettingsStatus = (state = '', action) => {
  switch (action.type) {
    case at.UPDATE_COMMUNICATION_SETTINGS_SUCCESS: {
      return 'loaded';
    }
    case at.UPDATE_COMMUNICATION_SETTINGS_REQUEST: {
      return 'loading';
    }
    case at.UPDATE_COMMUNICATION_SETTINGS_FAILURE: {
      return 'failed';
    }
    default:
      return state;
  }
};

export const userFlag = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_USER_FLAG_SUCCESS: {
      return { ...state, [action.data.key]: action.data.value };
    }
    default:
      return state;
  }
};

export const userFlagStatus = (state = {}, action) => {
  switch (action.type) {
    case at.FETCH_USER_FLAG_SUCCESS: {
      return { ...state, [action.data.key]: 'loaded' };
    }
    case at.FETCH_USER_FLAG_REQUEST: {
      return { ...state, [action.key]: 'loading' };
    }
    case at.FETCH_USER_FLAG_FAILURE: {
      return { ...state, [action.key]: 'failed' };
    }
    default:
      return state;
  }
};

export const setUserFlags = (state = {}, action) => {
  switch (action.type) {
    case at.SET_FLAG_REQUEST:
      return {
        ...state,
        loading: true
      };

    case at.SET_USER_FLAG_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.data.flagName]: action.data.value
      };

    case at.SET_FLAG_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    default:
      return state;
  }
};

export default combineReducers({
  profile,
  fetchStatus,
  emailSettings,
  emailSettingsStatus,
  updateStatus,
  updateEmailSettingsStatus,
  conciergeManager,
  conciergeManagerStatus,
  communicationSettings,
  communicationSettingsStatus,
  updateCommunicationSettingsStatus,
  updateUsernameStatus,
  updatePasswordStatus,
  updateErrorMessage,
  userFlag,
  userFlagStatus,
  setUserFlags
});
