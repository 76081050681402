/* eslint-disable import/first */
import ReactGA from 'react-ga';
import queryString from 'query-string';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import accounting from 'accounting';
import moment from 'moment';
import config from './config';
import { fetch } from './api/nowCourses';
import { COURSESCRIPTION_EVENTS, ITEM_TYPES } from './utils/constants';

const NOW_STATUS_CODES = { PUBLISHED: 'PUBLISHED' };
const COURSE_TYPES = { CD_LIVE: 'CD_LIVE' };

const { REACT_APP_ENABLE_ADA_WIDGET, REACT_APP_COURSESCRIPTION_ENABLED_KEY, REACT_APP_COURSESCRIPTION_ENABLED } =
  process.env;
const MIN_WIDTH = 768;
const ADA_COMPONENT_ID = 'userwayAccessibilityIcon';
const HIDE_ADA_COMPONENT_STYLE = 'display: none;';
const SHOW_ADA_COMPONENT_STYLE = 'display: inline-block !important;';

const { SUBSCRIPTION_TYPES } = config;

export const redirectTo = (url, newTab = false) => {
  if (url && url !== '') {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.href = url;
    }
  }
};

export const getTranscriptUrl = ({ pkLicense, cycleId }) => {
  let transcriptUrl = '';
  if (pkLicense > 0) {
    transcriptUrl = `/transcript/${pkLicense}`;

    if (cycleId) {
      transcriptUrl += `?cycle=${cycleId}`;
    }
  }
  return transcriptUrl;
};

export const getOverviewUrl = ({ pkLicense, cycleId }) => {
  let transcriptUrl = '';
  if (pkLicense > 0) {
    transcriptUrl = `/license/${pkLicense}/overview`;

    if (cycleId) {
      transcriptUrl += `?cycle=${cycleId}`;
    }
  }
  return transcriptUrl;
};

export const mapKeepLearningCourses = (courses) => {
  const keepCoursesResult = [];
  courses.forEach((item) => {
    const courseInfo = item.course;
    const profession = courseInfo.components[courseInfo.components.length - 1].profession;
    const totalHours = profession && profession.totalHours;
    const lmsInfo = item.lmsResult ? item.lmsResult : {};
    const { id } = courseInfo;
    const { courseId, courseName, providerName, studentProgress, coverImage, studentUrl } = lmsInfo;
    keepCoursesResult.push({
      id,
      courseId,
      courseName,
      providerName,
      studentProgress,
      coverImage,
      studentUrl,
      totalHours,
    });
  });
  return keepCoursesResult;
};

export function refineQuery(query) {
  const newParams = {};
  Object.keys(query).forEach((key) => {
    const value = query[key];
    if (value !== null && typeof value !== 'undefined' && value !== '') {
      if (isNaN(value)) {
        newParams[key] = value;
      } else {
        newParams[key] = parseFloat(value);
      }
    }
  });
  return newParams;
}

export function titleCase(text) {
  if (text) {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
  }
  return '';
}

export function setCookie({ name, value, exdays = 1, domain = 'cebroker.com' }) {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires= ${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};domain=${domain};path=/`;
}

export function getCookie(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let char = ca[i];
    while (char.charAt(0) === ' ') {
      char = char.substring(1);
    }
    if (char.indexOf(name) === 0) {
      return char.substring(name.length, char.length);
    }
  }
  return '';
}

export function deleteCookie(cookieName, domain) {
  const now = new Date();
  let cookie = `${cookieName}=;expires=${now.toUTCString()}`;
  if (domain) {
    cookie += `;domain=${domain}`;
  }
  document.cookie = cookie;
}

export function timeout(t) {
  return new Promise((res) => {
    setTimeout(() => {
      res();
    }, t);
  });
}

const getUseLocalStorage = (key) => {
  return window.sessionStorage.getItem(key);
};

const setUseLocalStorage = (key, initialValue) => {
  window.sessionStorage.setItem(key, initialValue);
};

const removeUseLocalStorage = (key) => {
  window.sessionStorage.removeItem(key);
};

export function getCourseRegistrationHierarchy({ provider = {}, registrationWebsite, defaultCourseUrl }) {
  return registrationWebsite || defaultCourseUrl || provider.website;
}

export function createLeavingCEBrokerUrl(url, providerName) {
  return `/leaving-cebroker?redirectTo=${encodeURIComponent(url)}&providerName=${encodeURIComponent(providerName)}`;
}

export const setSessionItem = (key, data) => {
  sessionStorage.setItem(key, data);
};
const getSessionItem = (key) => {
  return sessionStorage.getItem(key);
};

export const fullName = (firstName, lastName) => {
  return `${firstName ? firstName : ''} ${lastName ? lastName : ''}`.trim();
};

export const cleanStorageToken = (token) => {
  let cleanToken = '';
  if (token) {
    cleanToken = token.replace(/"/g, '');
  }
  return cleanToken;
};

const boardLogoUrl = (imgToken, stateCode) => {
  const { storageUrl, legacyUrl } = config;
  if (imgToken && imgToken.trim() !== '') {
    return `${storageUrl}/CEBroker/${imgToken}`;
  }
  switch (stateCode) {
    case 'WY':
      return `${legacyUrl}/assets/images/wyoming_board_logo.png`;
    case 'OH':
      return `${legacyUrl}/assets/images/ohio_board_logo.png`;
    case 'DC':
      return `${legacyUrl}/assets/images/dc_nursing_logo_2.png`;
    default:
      return `${legacyUrl}/assets/images/default_board_logo.png`;
  }
};

export const delay = (time) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, time);
  });
};

export function getSlug(string) {
  return string.toLowerCase().replace(/[^a-z0-9]/gi, '-');
}

export function isString(variable) {
  return !(!variable || variable.trim() === '' || typeof variable !== 'string');
}

export function getSlugFromName(string) {
  return getSlug(string).split('-').join(' ').replace(/  +/g, ' ').trim().split(' ').join('-');
}

export function getCourseSearchType() {
  return 'INTERNAL';
}

export function getAssociationLabelsFromComponents(components = []) {
  return components
    .filter((c) => c.profession.board.associationLabel && c.profession.board.associationLabel[0] !== '')
    .map((c) => c.profession.board.associationLabel[0])
    .filter((el, i, a) => i === a.indexOf(el));
}

export function convertHexToRGB(hex, opacity) {
  if (!hex) {
    return '';
  }

  const hexadecimal = hex.replace('#', '');
  const r = parseInt(hexadecimal.substring(0, 2), 16);
  const g = parseInt(hexadecimal.substring(2, 4), 16);
  const b = parseInt(hexadecimal.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
}

export function professionColorStyles(professionBrandColor = '') {
  return {
    backgroundColor: `${convertHexToRGB(professionBrandColor, 90)}`,
  };
}

export function addNewStyle(newStyle) {
  try {
    let styleElement = document.getElementById('styles_js');
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.type = 'text/css';
      styleElement.id = 'styles_js';
      document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
    styleElement.appendChild(document.createTextNode(newStyle));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error applying the new style to component', error);
  }
}

export function checkIfIsNowCourse({ nowStatusCode }) {
  const { PUBLISHED } = NOW_STATUS_CODES;
  return nowStatusCode === PUBLISHED;
}

export function checkIfIsNowLiveCourse({ nowStatusCode, type }) {
  const { PUBLISHED } = NOW_STATUS_CODES;
  const { CD_LIVE } = COURSE_TYPES;
  return nowStatusCode === PUBLISHED && type === CD_LIVE;
}

export function licenseeAuthInfoMinifier(authenticationObject) {
  const { authenticatedAccount } = authenticationObject;
  const {
    licenseeAccount: {
      subscriptions,
      user,
      licenses,
      associationUsersInfo,
      states: statesObject,
      isPaidByBoard,
      isWyoming,
      autoRenewSettings,
      isBetaAvailable,
    },
  } = authenticationObject;

  const {
    inAllowRegularSignup, // eslint-disable-line
    hasPasswordSet, // eslint-disable-line
    ...associationRest
  } = associationUsersInfo;

  const {
    subscriptionId, // eslint-disable-line
    ...autoRenewRest
  } = autoRenewSettings;

  const licenseeAccount = {
    subscriptions: subscriptions.map((subs) => ({
      type: subs.type,
      isftoAvailable: subs.isftoAvailable,
      addOns: subs.addOns,
      code: subs.code,
      name: subs.name,
      label: subs.label,
    })),
    user,
    licenses: licenses.map((l) => ({
      profession: {
        id: l.profession.id,
        name: l.profession.name,
        color: l.profession.color,
        isAvailableInCourseSearch: l.profession.isAvailableInCourseSearch,
        board: {
          state: {
            code: l.profession.board.state.code,
          },
        },
      },
    })),
    associationUsersInfo: associationRest,
    isPaidByBoard,
    isWyoming,
    autoRenewSettings: autoRenewRest,
    isBetaAvailable,
  };

  const minifiedStatesObject = {};
  let currentStateObject;
  for (const state in statesObject) {
    currentStateObject = statesObject[state];
    minifiedStatesObject[state] = {
      code: currentStateObject.code,
      professions: currentStateObject.professions.map((prof) => ({
        id: prof.id,
        name: prof.name,
        board: {
          state: {
            code: prof.board.state.code,
          },
        },
      })),
    };
  }

  licenseeAccount.states = minifiedStatesObject;
  return {
    authenticatedAccount,
    licenseeAccount,
  };
}

export function convertObjectToHash(obj = {}) {
  let strigifiedObject;
  try {
    strigifiedObject = JSON.stringify(obj);
  } catch (error) {
    console.error('Error hashing object', error); //eslint-disable-line
    return;
  }
  const hashedObject = window.btoa(strigifiedObject);
  return hashedObject;
}

export function forceAuthenticate(userId, loggedOnBehalf = false) {
  const { legacyUrl } = config;
  let query = '';
  if (loggedOnBehalf) {
    query = `?IdUserOnBehalfLogin=${userId}`;
  }
  const url = `${legacyUrl}/public/pb_auth_gateway.aspx${query}`;
  redirectTo(url);
}

export function removeUrlProtocol(url) {
  const regex = /^https:\/\//;
  return url.replace(regex, '//');
}

export const sendAnalyticsEvent = ({ category, action, label }) => {
  if (!category || !action || !label) {
    return;
  }

  ReactGA.event({
    category,
    action,
    label,
  });
};

export const getAccountTypeLabel = (subscriptionType, isFTOAvailable = false) => {
  const {
    AnalyticsTrackingEntities: { labels },
  } = config;
  let label;
  switch (subscriptionType.toUpperCase()) {
    case SUBSCRIPTION_TYPES.BAS:
      if (isFTOAvailable) {
        label = labels.basicWithFreeTrial;
      } else {
        label = labels.basicNoFreeTrial;
      }
      break;
    case SUBSCRIPTION_TYPES.FTO:
      label = labels.FreeTrial;
      break;
    case SUBSCRIPTION_TYPES.PRO:
      label = labels.Professional;
      break;
    case SUBSCRIPTION_TYPES.CON:
      label = labels.Concierge;
      break;
    case SUBSCRIPTION_TYPES.PRO_PLUS:
      label = labels.ProfessionalPlus;
      break;
    case SUBSCRIPTION_TYPES.CON_PLUS:
      label = labels.ConciergePlus;
      break;
    case SUBSCRIPTION_TYPES.EMP:
      label = labels.Employee;
      break;
    default:
      label = 'NotSet';
  }
  return label;
};

export const getActionsBySubscriptionType = (subscriptionType) => {
  const {
    AnalyticsTrackingEntities: { actions },
  } = config;

  const actionOptions = {
    PRO: actions.accountOptionPRO,
    CON: actions.accountOptionCON,
    PRO_PLUS: actions.accountOptionPRO_PLUS,
    CON_PLUS: actions.accountOptionCON_PLUS,
  };
  return actionOptions[subscriptionType.toUpperCase()] || '';
};

export function getNotificationSettingsText(subscriptionType) {
  return subscriptionType !== 'EMP'
    ? 'These notifications will be sent prior to the deadline.'
    : 'These notifications will be sent prior to the deadline. Note: Some settings are controlled by your employer';
}

export const cebBanner = 'ceb-banner';

export const getPermissionByProfessionId = (sections = {}, professionId = 0) => {
  const profession = sections.find((l) => l.professionId === professionId) || {};

  return profession;
};

export function clearProps(object) {
  const newObject = {};
  Object.keys(object).forEach((key) => {
    if (object[key] !== null && typeof object[key] !== 'undefined' && object[key] !== '') {
      newObject[key] = object[key];
    }
  });
  return newObject;
}

export function mergeObjectsAndClean(obj1, obj2) {
  return clearProps({ ...obj1, ...obj2 });
}

export function changeUrlParams(history, location) {
  return (params, state = {}) => {
    const previousQuery = queryString.parse(location.search);
    const newQuery = mergeObjectsAndClean(previousQuery, params);
    if (isEqual(newQuery, previousQuery)) {
      return;
    }
    history.push({
      pathname: location.pathname,
      search: queryString.stringify(newQuery),
      state,
    });
  };
}

export function getCurrentPriceByCourse(data = {}) {
  const coursePrice = {
    priceLabel: '',
    isContactProvider: false,
  };
  const { isFree, price } = data;

  if (isFree) {
    coursePrice.priceLabel = 'Free';
  } else if (price && price !== '99999.0') {
    coursePrice.priceLabel = accounting.formatMoney(price, '$');
  } else {
    coursePrice.isContactProvider = true;
    coursePrice.priceLabel = 'Price not reported';
  }
  return coursePrice;
}

export function getTotalHoursCourseByComponents(components = []) {
  let totalHours = '';

  if (components.length > 0) {
    totalHours = components && components[components.length - 1].profession.totalHours;
  }

  return totalHours;
}

export function getCourseComponentsByProfessionId(course = {}, professionId) {
  return course.components.filter((component) => component.profession.id === professionId);
}

export function evaluateDiscoverTabVisibility({ discoverPermissions, accountAddOns = {} }) {
  if (!process.env.REACT_APP_ENABLE_LEARNING_DISCOVERY_TAB === 'true') {
    return false;
  }

  if (accountAddOns.isCoursescription) {
    return false;
  }

  let canSeeDiscover = false;

  for (const profession in discoverPermissions) {
    const { isAbleToViewDiscoverTab } = discoverPermissions[profession];
    if (isAbleToViewDiscoverTab) {
      canSeeDiscover = true;
      break;
    }
  }

  return canSeeDiscover;
}

export function retryAsyncFunction(awaitableFunc, retries = 3, waitTime = 10000) {
  if (typeof awaitableFunc !== 'function') {
    throw TypeError('Invalid parameter, you should pass a function');
  }

  let attempts = 0;
  return (async function inner() {
    try {
      const response = await awaitableFunc();
      return response;
    } catch (error) {
      attempts++;
      // eslint-disable-next-line no-console
      console.error(`Error executing ${awaitableFunc.name}, attempt: ${attempts}`);

      if (attempts >= retries) {
        // eslint-disable-next-line no-console
        console.warn(`Max attempts reached executing ${awaitableFunc.name}`);
        throw error;
      }
      await timeout(waitTime);
      return inner();
    }
  })();
}

export function formatCurrency(value) {
  return Number(value.toFixed(2));
}

export async function courseInProgressValidation(userId, courseId) {
  const { data } = await fetch({
    userId,
    courseId,
    courseStatus: 'progress',
  });
  const isInProgress = data.items.length > 0;
  let studentUrl;
  if (isInProgress) {
    const [course] = data.items;
    studentUrl = course.studentUrl;
  }

  return studentUrl;
}

export function getShoppingCartData(key) {
  return getCookie(key) ? JSON.parse(getCookie(key)) : {};
}

export function getLocalStorage(key) {
  let value = localStorage.getItem(key);
  if (!value || value.trim() === '' || typeof value !== 'string') {
    return {};
  }
  try {
    value = JSON.parse(value);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Error trying to get ${key} from localStorage`, error);
  }
  return value;
}

export const asyncTimeout = (time = 2000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

export const showToastNotification = ({
  message,
  addNotification,
  onActionClick = () => { },
  actionText = 'close',
  timeout = 3000,
}) => {
  addNotification({ id: Math.random(), message, timeout, actionText, onActionClick }, { single: true });
};

export function registerEventGTM({ event, data = {} }) {
  window.dataLayer.push({ event, ...data });
}

export function dateStringFormat(date, inputFormat = 'MM/DD/YYYY', outputFormat = 'MMM DD YYYY') {
  return moment(date, inputFormat).format(outputFormat);
}

/**
 *  Return a name with the firsts letters capitalized
 * @param {string} name a person name
 * @returns {string}
 */
export function formatAuthorName(name = '') {
  return name.toLowerCase().replace(/\b\w/g, (c) => c.toUpperCase());
}

/**
 *  Return a date with format
 * @param {number} day days that you need to add to the current date
 * @returns {string}
 */
export const getDate = (day) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + day);

  return `${months[currentDate.getMonth()]} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
};

export function handleDisplayAdaComponent(displayAdaComponent = true) {
  if (REACT_APP_ENABLE_ADA_WIDGET) {
    const userWayIcon = document.getElementById(ADA_COMPONENT_ID);
    const userWayIconStyle =
      displayAdaComponent || window.innerWidth >= MIN_WIDTH ? SHOW_ADA_COMPONENT_STYLE : HIDE_ADA_COMPONENT_STYLE;

    if (userWayIcon) {
      userWayIcon.setAttribute('style', userWayIconStyle);
    }
  }
}

export const isFavoriteCourse = ({ items = [], courseId }) => {
  return items.some((item) => item.courseId === courseId);
};

const roundValue = (value) => {
  return Number.parseFloat(value).toFixed(2);
};

export const getSubscriptionConditions = ({
  currentSubscriptionType = 'none',
  subscriptionTypeToFind,
  subscriptionPlans = [],
  event = COURSESCRIPTION_EVENTS.BADGE,
}) => {
  const coursescriptionEnabledKeyValue = JSON.parse(localStorage.getItem(REACT_APP_COURSESCRIPTION_ENABLED_KEY));

  const isCoursescriptionEnabled = coursescriptionEnabledKeyValue || REACT_APP_COURSESCRIPTION_ENABLED === 'true';

  const isProPlusSubscriptionEnabled = subscriptionPlans.some(
    (subscriptionPlan) => subscriptionPlan.subscriptionType === subscriptionTypeToFind
  );

  const conditions = {
    [COURSESCRIPTION_EVENTS.BADGE]: {
      [SUBSCRIPTION_TYPES.PRO_PLUS]: true,
      [SUBSCRIPTION_TYPES.PRO]: isProPlusSubscriptionEnabled,
      [SUBSCRIPTION_TYPES.BAS]: isProPlusSubscriptionEnabled,
      [SUBSCRIPTION_TYPES.CON]: false,
      [SUBSCRIPTION_TYPES.FTO]: false,
      [SUBSCRIPTION_TYPES.EMP]: false,
      none: false,
    },
  };

  return isCoursescriptionEnabled && conditions[event][currentSubscriptionType];
};

const formatDateWithUtc = (date, format = 'MM/DD/YYYY') => moment.utc(date).format(format);

export const getProfessionInfoFromLicenseAccount = (professionId, professions = []) => {
  const { name: professionName, board = {} } = professions.find(({ id }) => id === professionId) || {};
  const stateName = get(board, 'state.name');
  return {
    professionName,
    stateName,
  };
};

export const getExpirationTooltipMessage = (licenses = []) => {
  if (licenses.length === 0) return '';
  let message = 'You will not receive credit for ';

  const expirationMessages = licenses.map(({ number, expirationDate: professionExpirationDate }) => {
    const formattedProfessionExpirationDate = formatDateWithUtc(professionExpirationDate);
    return `${number} expired ${formattedProfessionExpirationDate}`;
  });

  message += expirationMessages.join(', ');
  return `${message}.`;
};

export const getCheckoutSubscriptionItem = (checkout = {}) => {
  const { items = [] } = checkout;
  const checkoutSubscriptionItem = items.find((item = {}) => item.type === ITEM_TYPES.SUBSCRIPTION) || {};
  return checkoutSubscriptionItem;
};

export const buildItemsForPurchaseEvents = (items = []) => {
  const mappedItems = items.map((item = {}) => {
    const { _id, name, type } = item;
    let productCategory = ITEM_TYPES.COURSE;

    if (type === ITEM_TYPES.SUBSCRIPTION) {
      productCategory = ITEM_TYPES.UPGRADE;
    }

    const itemName = productCategory === ITEM_TYPES.UPGRADE ? `${name} Upgrade`: name;

    return {
      ...item,
      item_id: _id,
      item_name: itemName,
      product_category: productCategory,
    }
  });

  return mappedItems;
}

export const mapGTMCheckoutDataFromShoppingCart = (checkout = {}) => {
  const { couponId, tax, items = [], chargeId, amountTotal, licensee = {} } = checkout;
  const checkoutSubscriptionItem = getCheckoutSubscriptionItem(checkout);
  const { subscriptionType: itemSubscriptionType } = checkoutSubscriptionItem;
  const { subscriptionType } = licensee;
  let isNewPayingAccount = 1;

  const mappedItems = buildItemsForPurchaseEvents(items);
  if (subscriptionType === SUBSCRIPTION_TYPES.PRO && itemSubscriptionType && itemSubscriptionType === SUBSCRIPTION_TYPES.PRO_PLUS) {
    isNewPayingAccount = 0;
  }

  if (isEmpty(checkoutSubscriptionItem)) {
    isNewPayingAccount = 0;
  }

  const dataEvent = {
    currency: 'USD',
    value: amountTotal,
    coupon: couponId,
    tax,
    is_new_paying_account: isNewPayingAccount,
    transaction_id: chargeId,
    items: mappedItems,
  }

  return dataEvent;
};

export const mapGTMCheckoutDataFromUpgradePage = (eventData = {}) => {
  const { verisignTransactionId, discountCode, feeWithDiscount, fee, itemName, itemId, itemType = ITEM_TYPES.UPGRADE, subscriptionType, itemSubscriptionType, tax } = eventData;
  let isNewPayingAccount = 1;

  if (subscriptionType === SUBSCRIPTION_TYPES.PRO && itemSubscriptionType && itemSubscriptionType === 'con') {
    isNewPayingAccount = 0;
  }

  const data = {
    transaction_id: verisignTransactionId,
    value: discountCode.length > 0 ? feeWithDiscount : fee,
    tax,
    currency: 'USD',
    coupon: discountCode,
    is_new_paying_account: isNewPayingAccount,
    items: [
      {
        item_id: itemId,
        item_name: itemName,
        product_category: itemType,
      },
    ],
  };

  return data;
};

export const validateExistingGTMEvent = ({ eventName, extraFieldName, extraFieldValue }) => {
  const existingEvent = window.dataLayer.some(
    item => item.event === eventName && item[extraFieldName] === extraFieldValue
  );
  return existingEvent;
};

export const getSubscriptionPlanFromUpgrade = (subscription, subscriptionPlans = []) => {
  const newSubscriptionPlan = subscriptionPlans.find(subscriptionPlan =>
    (subscriptionPlan.subscriptionType === subscription.toUpperCase())
  ) || {};
  return newSubscriptionPlan;
}

export default {
  getCookie,
  setCookie,
  deleteCookie,
  redirectTo,
  getTranscriptUrl,
  getOverviewUrl,
  setSessionItem,
  getSessionItem,
  fullName,
  cleanStorageToken,
  boardLogoUrl,
  delay,
  getSlug,
  isString,
  getSlugFromName,
  getCourseSearchType,
  getAssociationLabelsFromComponents,
  convertHexToRGB,
  professionColorStyles,
  checkIfIsNowCourse,
  checkIfIsNowLiveCourse,
  licenseeAuthInfoMinifier,
  convertObjectToHash,
  forceAuthenticate,
  removeUrlProtocol,
  sendAnalyticsEvent,
  getAccountTypeLabel,
  cebBanner,
  getUseLocalStorage,
  setUseLocalStorage,
  removeUseLocalStorage,
  mapKeepLearningCourses,
  getPermissionByProfessionId,
  getNotificationSettingsText,
  changeUrlParams,
  getCurrentPriceByCourse,
  getTotalHoursCourseByComponents,
  getCourseComponentsByProfessionId,
  evaluateDiscoverTabVisibility,
  retryAsyncFunction,
  addNewStyle,
  formatCurrency,
  courseInProgressValidation,
  getShoppingCartData,
  getLocalStorage,
  showToastNotification,
  registerEventGTM,
  dateStringFormat,
  refineQuery,
  getDate,
  handleDisplayAdaComponent,
  isFavoriteCourse,
  roundValue,
  getActionsBySubscriptionType,
  getSubscriptionConditions,
  formatDateWithUtc,
  getProfessionInfoFromLicenseAccount,
  getExpirationTooltipMessage,
  getCheckoutSubscriptionItem,
  mapGTMCheckoutDataFromShoppingCart,
  validateExistingGTMEvent,
  mapGTMCheckoutDataFromUpgradePage,
  getSubscriptionPlanFromUpgrade
};
