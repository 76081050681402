import React from 'react';
import PropTypes from 'prop-types';
import { Icon, SkeletonLoader } from 'emerald-ui';
import { AnalyticsTrackingEntities } from '../../config';
import './RenewalRequirementBanner.css';

const { actions } = AnalyticsTrackingEntities;
const { REACT_APP_ENABLE_UPGRADE_MESSAGE } = process.env;

class RenewalRequirementBanner extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickLinkRequirements = this.handleClickLinkRequirements.bind(this);
    this.handleClickRenewTextLink = this.handleClickRenewTextLink.bind(this);
  }
  handleClickLinkRequirements(e) {
    e.preventDefault();
    const { handleTrackedClick, renewalRequirementsLink } = this.props;
    handleTrackedClick(actions.sectionBoardTextLink);
    window.open(renewalRequirementsLink, '_blank');
  }
  handleClickRenewTextLink(e) {
    e.preventDefault();
    const { handleTrackedClick, upgradeLink, history } = this.props;
    handleTrackedClick(actions.sectionUpgradeTextLink);
    history.push(upgradeLink);
  }
  render() {
    const { isFTOAvailable, renewalRequirementsLink, complianceStatus, transcriptStatus, upgradeLink } = this.props;

    return (
      <div className="renewal-requirements-container">
        {transcriptStatus === 'loading' ? (
          <React.Fragment>
            <SkeletonLoader loading height="30px" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="renewal-requirements-text">
              Not complete? View your board&#39;s specific CE/CME requirements{' '}
              {renewalRequirementsLink ? (
                <a
                  href={`//${renewalRequirementsLink}`}
                  target="_blank"
                  onClick={this.handleClickLinkRequirements}
                  rel="noopener noreferrer"
                >
                  here
                  <Icon name="launch" />
                </a>
              ) : (
                ''
              )}{' '}
              to manually calculate what is needed for renewal.
            </p>
            {['IN_PROG', 'NONE'].includes(complianceStatus) && REACT_APP_ENABLE_UPGRADE_MESSAGE === 'true' && (
              <p>
                You can upgrade to the Professional Account and we will guide you to CE compliance.{' '}
                <a href={upgradeLink} onClick={this.handleClickRenewTextLink}>
                  {isFTOAvailable ? 'Try it out.' : 'Renew Subscription.'}
                </a>
              </p>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

RenewalRequirementBanner.propTypes = {
  isFTOAvailable: PropTypes.bool,
  renewalRequirementsLink: PropTypes.string,
  complianceStatus: PropTypes.string,
  transcriptStatus: PropTypes.string,
  handleTrackedClick: PropTypes.func,
  upgradeLink: PropTypes.string,
  history: PropTypes.object,
  licenseLabel: PropTypes.string,
};

RenewalRequirementBanner.defaultProps = {
  isFTOAvailable: false,
  renewalRequirementsLink: '',
  complianceStatus: '',
};

export default RenewalRequirementBanner;
