import at from './types';

export const fetch = (meta = {}) => ({
  type: at.FETCH_REQUEST,
  meta,
});

export const fetchEmailSettings = (meta = {}) => ({
  type: at.FETCH_EMAIL_SETTINGS_REQUEST,
  meta,
});

export const update = (userProfile, meta = {}) => ({
  type: at.UPDATE_REQUEST,
  userProfile,
  meta,
});

export const updateUsername = (username, meta = {}) => ({
  type: at.UPDATE_USERNAME_REQUEST,
  username,
  meta,
});

export const updatePassword = (currentPassword, newPassword, confirmPassword, meta = {}) => ({
  type: at.UPDATE_PASSWORD_REQUEST,
  currentPassword,
  newPassword,
  confirmPassword,
  meta,
});

export const updateEmailSettings = (emailSettings, meta = {}) => ({
  type: at.UPDATE_EMAIL_SETTINGS_REQUEST,
  emailSettings,
  meta,
});

export const resetUpdateStatus = () => ({
  type: at.RESET_UPDATE_STATUS,
});

export const resetUpdateEmailSettingsStatus = () => ({
  type: at.RESET_UPDATE_EMAIL_SETTINGS_STATUS,
});

export const fecthConciergeManager = () => ({
  type: at.FETCH_CONCIERGE_MANAGER_REQUEST,
});

export const fetchCommunicationSettings = () => ({
  type: at.FETCH_COMMUNICATION_SETTINGS_REQUEST,
});

export const fetchUserFlag = (key, meta = {}) => ({
  type: at.FETCH_USER_FLAG_REQUEST,
  key,
  meta,
});

export const updateCommunicationSettings = (communicationSettings) => ({
  type: at.UPDATE_COMMUNICATION_SETTINGS_REQUEST,
  communicationSettings,
});

export const setUserFlag = (key, value) => ({
  type: at.SET_FLAG_REQUEST,
  key,
  value
});