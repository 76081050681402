export const COURSESCRIPTION_EVENTS = {
  BADGE: 'badge',
  START_COURSE_BUTTON: 'start_course_button',
  PRO_PLUS_TOGGLE_DISPLAYED: 'pro_plus_toggle_displayed',
  PRO_PLUS_TOGGLE_CHECKED: 'pro_plus_toggle_checked',
};

export const AUTHENTICATION_STATUSES = { UNAUTHENTICATED: 'unauthenticated', AUTHENTICATED: 'authenticated' };

export const SUBSCRIPTION_TYPES = {
  PRO: 'PRO',
  CON: 'CON',
  BAS: 'BAS',
  PRO_PLUS: 'PRO_PLUS',
  FTO: 'FTO',
  EMP: 'EMP',
};

export const ITEM_TYPES = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  COURSE: 'COURSE',
  UPGRADE: 'UPGRADE',
}
