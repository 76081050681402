import React from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import ReactGA from 'react-ga';
import { Button, Spinner } from 'emerald-ui';
import { saveClick } from '../../../../api/clicks';
import { createCheckoutSession } from '../../../../api/checkout';
import StarRating from '../../../StarRating';
import {
  getCourseSearchType,
  checkIfIsNowCourse,
  redirectTo,
  courseInProgressValidation,
  getLocalStorage,
  showToastNotification,
} from '../../../../utils';
import config from '../../../../config';

import './CourseRegisterInformation.css';
import * as flagsApi from '../../../../api/flags';
import ProPlusBadge from 'components/common/ProPlusBadge';
const {
  REACT_APP_ENABLE_CHECKOUT: enableCheckout,
  REACT_APP_ENABLE_CART: isEnabledCart,
  REACT_APP_SHOPPING_CART_LOCALSTORAGE_KEY: shoppingCartKey,
} = process.env;

const { getCourseSearchUrl } = config;

class CourseRegisterInformation extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorExists: false,
      errorMessage: '',
      isCheckoutLoading: false,
      seeNewAcademyImplementation: false,
      isCourseInShoppingCart: false,
      isCourseInProgress: false,
      canViewShoppingCartOption: false,
      isRegisterInformationLoading: true,
      isFloating: false,
    };
    this.getFlags();
  }

  async componentDidMount() {
    const canViewShoppingCartOption = await this.isShoppingCartEnable();

    this.setState({
      canViewShoppingCartOption,
      isRegisterInformationLoading: false,
    });
  }

  componentDidUpdate(prevProps) {
    const { saveCartItemStatus, clearStatusCart, totalItemsInCart } = this.props;
    const { isFloating } = this.state;

    if (prevProps.saveCartItemStatus === 'loading' && saveCartItemStatus === 'failed') {
      this.showErrorMessage(`Error adding the course, please try again later.`, isFloating);
      this.setState({ isRegisterInformationLoading: false });
      clearStatusCart();
      return;
    }

    if (saveCartItemStatus === 'loaded' && totalItemsInCart > prevProps.totalItemsInCart) {
      this.setState({
        isCourseInShoppingCart: this.validateCourseInShoppingCart(),
        isRegisterInformationLoading: false,
      });
    }
  }

  async isShoppingCartEnable() {
    const { courseDetail, isFree, authenticationStatus } = this.props;
    const isNowCourse = checkIfIsNowCourse(courseDetail);
    const isNotLoggedIn = authenticationStatus === 'unauthenticated';
    const isEnabledCartLocalStorage = getLocalStorage(shoppingCartKey);

    try {
      const canUseShoppingCart = isEnabledCartLocalStorage === true || isEnabledCart === 'true';

      const listToNotShowCart = [
        isNotLoggedIn,
        isFree,
        !isNowCourse,
        !canUseShoppingCart,
        await this.isCourseInProgress(),
      ];

      if (listToNotShowCart.includes(true)) {
        return false;
      }

      this.setState({ isCourseInShoppingCart: this.validateCourseInShoppingCart() });

      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  handleRegisterClick = () => {
    const { courseDetail, isFeatured, offeringId, licenseeId, isFromSavedCourses, isOutOfCourseSearch, categoryGA } =
      this.props;
    const { id: courseId, provider = {} } = courseDetail;
    const { id: providerId } = provider;

    const registerUrl = courseDetail.registrationWebsite || courseDetail.defaultCourseUrl || provider.website;

    if (isOutOfCourseSearch) {
      // Send an event to google analytics
      ReactGA.event({
        category: categoryGA ? categoryGA : 'lic_beta_register_ad',
        action: 'click',
        label: `providerId=${providerId},courseId=${courseId},offeringId=${offeringId}`,
      });
      saveClick({
        type: 'REGISTER_BUTTON',
        providerId,
        courseId,
        offeringId,
        clickedURL: registerUrl,
        courseSearchType: getCourseSearchType(licenseeId),
        isFeaturedCourse: true,
      });
      return;
    }

    // Send an event to google analytics
    ReactGA.event({
      category: 'provider_website_link',
      action: 'click',
      label: `${isFeatured ? 'Featured-' : ''}${registerUrl}`,
    });
    if (isFeatured) {
      // Send an event to google analytics
      ReactGA.event({
        category: 'featured_provider_link_cs_v2',
        action: 'click',
      });

      ReactGA.event({
        category: 'featured_registration_button',
        action: 'click',
        label: registerUrl,
      });
    }
    let type = 'REGISTER_BUTTON';
    if (isFromSavedCourses) {
      type += '_FAVORITE';
    }
    saveClick({
      type,
      providerId,
      courseId,
      offeringId,
      clickedURL: registerUrl,
      courseSearchType: getCourseSearchType(licenseeId),
      isFeaturedCourse: isFeatured,
    });
  };

  handleRedirection = async (url, isNowCourse, e) => {
    e.preventDefault();
    const { seeCheckoutPage, offeringId, customerId, courseDetail, userId, price, location, isFloating } = this.props;
    const { provider = {}, id: courseId } = courseDetail;
    const cancelUrl = `${config.licenseesUrl}${location.pathname}${location.search}`;
    this.handleRegisterClick();

    if (isNowCourse && url === 'checkout' && (seeCheckoutPage || enableCheckout === 'true')) {
      this.setState({
        isCheckoutLoading: true,
      });
      const data = {
        userId: userId.toString(),
        customerId,
        courses: [
          {
            offeringId: offeringId.toString(),
            amount: price,
            courseId: courseId.toString(),
            providerId: provider.id.toString(),
          },
        ],
        cancelUrl,
      };
      try {
        const studentUrl = await courseInProgressValidation(userId, courseId);
        if (studentUrl) {
          redirectTo(studentUrl, false);
        } else {
          const {
            data: { url },
          } = await createCheckoutSession(data);
          redirectTo(url, false);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        this.setState({ isCheckoutLoading: false });
        this.showErrorMessage('Error loading checkout. Please try again later.', isFloating);
      }
    } else {
      redirectTo(url, true);
    }
  };

  handleAddCourseToCart = async (course, e) => {
    e.preventDefault();
    const { saveCartItem, isFloating, professionIds, isFeatured } = this.props;
    const [professionId] = professionIds;
    this.setState({ isFloating, isRegisterInformationLoading: true });

    saveCartItem({ courses: [{ ...course, professionId, isFeatured }] });
  };

  handleViewCartClick = async (e, userId) => {
    e.preventDefault();
    const { loggedOnBehalf } = this.props;
    const queryParams = {
      redirect_to: 'cart',
    };
    if (loggedOnBehalf) {
      queryParams.userId = userId;
    }
    redirectTo(getCourseSearchUrl(queryParams, false));
  };

  validateCourseInShoppingCart() {
    const {
      courseDetail: { id: courseId },
      coursesInCart,
    } = this.props;

    const isCourseInShoppingCart = coursesInCart.some((course) => Number(course.courseId) === courseId);

    return isCourseInShoppingCart;
  }

  async isCourseInProgress() {
    const {
      courseDetail: { id: courseId },
      userId,
    } = this.props;

    const studentUrl = await courseInProgressValidation(userId, courseId);

    if (studentUrl) {
      this.setState({ isCourseInProgress: true });
    }

    return Boolean(studentUrl);
  }

  async getFlags() {
    const { userId } = this.props;
    let data = {};
    try {
      const result = await flagsApi.getFlag({
        userId: userId,
        key: 'seeNewAcademyImplementation',
      });
      data = result.data;
    } catch (error) {
      console.log(error); //  eslint-disable-line
    }
    if (data.value === 'true') {
      this.setState({
        seeNewAcademyImplementation: true,
      });
    }
  }

  showErrorMessage(errorMessage = '', isFloating = false) {
    this.setState({
      errorExists: true,
      errorMessage,
    });

    if (isFloating) {
      const { addNotification } = this.props;
      showToastNotification({
        message: errorMessage,
        addNotification,
      });
    }
  }

  render() {
    const {
      isFree,
      price,
      courseDetail,
      showTotalHours,
      professionIds,
      userId,
      authenticationStatus,
      isFloating,
      offeringId,
      shouldShowProPlusBadge,
    } = this.props;
    const {
      seeNewAcademyImplementation,
      isCheckoutLoading,
      errorExists,
      canViewShoppingCartOption,
      isRegisterInformationLoading,
      isCourseInShoppingCart,
      isCourseInProgress,
      errorMessage,
    } = this.state;
    const { provider = {}, components = [], id: courseId, ratingCount, rating } = courseDetail;
    const isNowCourse = checkIfIsNowCourse(courseDetail);

    const encodedUrl = encodeURIComponent(`${config.courseSearchUrl}/login?redirect_to=lms-redirection-${courseId}`);

    const isNotLoggedIn = authenticationStatus === 'unauthenticated';
    const nowCourseUrl = (() => {
      if (isNotLoggedIn) {
        return `${config.signInUrl}?continue_to=${encodedUrl}`;
      }
      return `${config.lmsUrl}?clientCourseId=${courseId}&clientLicenseeId=${userId}`;
    })();

    const nowCourseUrlAcademyImplementation = isNotLoggedIn
      ? `${config.signInUrl}?continue_to=${encodedUrl}`
      : `${config.lmsUrlAcademyImplementation}/${userId}-${courseId}`;

    let priceLabel = '';
    let isContactProvider = false;
    if (isFree) {
      priceLabel = 'Free';
    } else if (price && price !== '99999.0') {
      priceLabel = accounting.formatMoney(price, '$');
    } else {
      isContactProvider = true;
      priceLabel = 'Price not reported';
    }

    let totalHours = 0;

    if (professionIds.length > 0) {
      const component = components.find(({ profession }) => professionIds.includes(profession.id));
      const { profession } = component;
      totalHours = profession ? profession.totalHours : 0;
    }

    const registerUrl = courseDetail.registrationWebsite || courseDetail.defaultCourseUrl || provider.website;
    const urlRegEx = /^(http|https):\/\//;
    const isValidUrl = registerUrl ? urlRegEx.test(registerUrl.toLowerCase()) : false;
    const courseRegisterUrl = `/leaving-cebroker?redirectTo=${encodeURIComponent(
      registerUrl
    )}&providerName=${encodeURIComponent(provider.name)}`;

    const getButtonLabel = (canViewShoppingCartOption, isNowCourse, isCourseInProgress) => {
      if (canViewShoppingCartOption) {
        return 'BUY NOW';
      } else if (isNowCourse) {
        return isCourseInProgress ? 'CONTINUE' : 'START COURSE';
      }
      return 'LEARN MORE';
    };

    return (
      <div className="floating-content course-register-information">
        <div className="course-register-information-container">
          <div className="course-register-information-header">
            <div className="course-register-information-info">
              <span className={`course-detail-price ${isContactProvider ? 'price-not-reported' : ''}`}>
                {isContactProvider ? 'Price not reported' : priceLabel}
              </span>
              {showTotalHours && (
                <p className="course-detail-hours">{`${totalHours} ${totalHours > 1 ? 'Hours' : 'Hour'}`}</p>
              )}
            </div>
            <StarRating
              rating={rating}
              ratingCount={ratingCount}
              showRatingCount
              reviewsLink="#course-rating-feedback-link"
            />
          </div>
          {shouldShowProPlusBadge && <ProPlusBadge showCompleteText />}
          {isRegisterInformationLoading ? (
            <Spinner />
          ) : !isCourseInShoppingCart ? (
            <div className="course-register-information-register-section">
              {registerUrl && isValidUrl && !seeNewAcademyImplementation && !canViewShoppingCartOption && (
                <Button
                  className="course-detail-register"
                  color={!isNowCourse ? 'info' : 'warning'}
                  loading={isCheckoutLoading ? true : false}
                  disabled={isCheckoutLoading ? true : false}
                  onClick={(event) =>
                    this.handleRedirection(!isNowCourse ? courseRegisterUrl : nowCourseUrl, isNowCourse, event)
                  }
                  target="_blank"
                >
                  <span>{getButtonLabel(canViewShoppingCartOption, isNowCourse, isCourseInProgress)}</span>
                </Button>
              )}
              {registerUrl && isValidUrl && canViewShoppingCartOption && (
                <Button
                  className="course-detail-register"
                  color="default"
                  onClick={(event) =>
                    this.handleAddCourseToCart({ courseId, providerId: provider.id, amount: price, offeringId }, event)
                  }
                >
                  <span>ADD TO CART</span>
                </Button>
              )}
              {errorExists && !isFloating && <p className="error-checkout text-danger">{errorMessage}</p>}
              {registerUrl && isValidUrl && seeNewAcademyImplementation && (
                <Button
                  className="course-detail-register"
                  color={!isNowCourse ? 'info' : 'warning'}
                  href={!isNowCourse ? registerUrl : nowCourseUrlAcademyImplementation}
                  onClick={this.handleRegisterClick}
                  target="_blank"
                >
                  <span>{isNowCourse ? 'START COURSE' : 'LEARN MORE'}</span>{' '}
                </Button>
              )}
            </div>
          ) : (
            <div className="course-register-information-register-section">
              <Button
                className="course-detail-register"
                color="default"
                onClick={(event) => this.handleViewCartClick(event, userId)}
              >
                <span>VIEW CART</span>
              </Button>
              <p className="course-detail-added-cart">Item added to cart!</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

CourseRegisterInformation.propTypes = {
  isFeatured: PropTypes.bool,
  isFree: PropTypes.bool,
  price: PropTypes.string,
  courseDetail: PropTypes.object,
  showTotalHours: PropTypes.bool,
  offeringId: PropTypes.number,
  licenseeId: PropTypes.number,
  isFromSavedCourses: PropTypes.bool,
  professionIds: PropTypes.array,
  isOutOfCourseSearch: PropTypes.bool,
  categoryGA: PropTypes.string,
  userId: PropTypes.number,
  authenticationStatus: PropTypes.string,
  seeCheckoutPage: PropTypes.bool,
  customerId: PropTypes.string,
  isFloating: PropTypes.bool,
  addNotification: PropTypes.func,
  location: PropTypes.object,
  saveCartItem: PropTypes.func,
  coursesInCart: PropTypes.array,
  saveCartItemStatus: PropTypes.string,
  clearStatusCart: PropTypes.func,
  loggedOnBehalf: PropTypes.bool,
  totalItemsInCart: PropTypes.number,
  shouldShowProPlusBadge: PropTypes.bool,
};

CourseRegisterInformation.defaultProps = {
  courseDetail: {},
  showTotalHours: true,
  isFromSavedCourses: false,
  coursesInCart: [],
  professionIds: [],
  shouldShowProPlusBadge: false,
};

export default CourseRegisterInformation;
