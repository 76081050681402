import { put, call, select } from 'redux-saga/effects';

import * as api from '../../../api/flags';
import at from '../types';

export default function* setUserFlag({ key, value }) {
  const state = yield select();
  const user = state.authentication.licenseeAccount.user;

  try {
    const { data } = yield call(api.setFlag, { userId: user.id, key, value });
    yield put({ type: at.SET_FLAG_SUCCESS, data });
  } catch (error) {
    const { data } = error.response;
    console.error(error); // eslint-disable-line
    yield put({ type: at.SET_FLAG_FAILURE, data });
  }
}