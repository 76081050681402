import React from 'react';
import PropTypes from 'prop-types';
import * as authentication from '../../api/authentication';
import queryString from 'query-string';
import Button from 'emerald-ui/lib/Button';
import LoadingPage from '../LoadingPage';
import './LoginPage.css';
import config from '../../config';
import utils, { asyncTimeout } from '../../utils';
import { getCheckout, updateTalonOneProfile } from '../../api/checkout';
import { coursePurchaseEvent } from '../../mappings';

const { REACT_APP_LICENSES_LIMIT = 2, REACT_APP_ENABLE_GOOGLE_TAG_MANAGER = false } = process.env;
const { GTM_EVENTS } = config;

const failureSourceMessageBody = {
  SHOPPING_CART_CHECKOUT_VALIDATION: (
    <div>
      <p className="text-color">There was an error trying to update your account with your purchase(s).</p>
      <p className="text-color">
        Please click Retry. If the error persists <a href={config.supportData.url}>contact support.</a>
      </p>
    </div>
  ),
  AUTHENTICATION: (
    <p className="text-color">
      There was an error trying to obtain your user information. You can retry or come back later. Also, you can{' '}
      <a href={config.supportData.url}>contact support.</a>
    </p>
  ),
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.retryCount = 0;
    this.associationCode = '';
    this.associationReturnURL = '';
    this.state = {
      retryFailed: false,
      isRetrying: false,
      shouldDenyAccess: false,
      failureSource: 'AUTHENTICATION',
    };
  }

  componentDidMount() {
    this.authenticate();
  }

  async authenticateRequest() {
    const { token: cookieToken, query } = this.props;
    const { user_id, allow_inactive_user } = query;
    try {
      return await authentication.authenticate(cookieToken, 'bearer', user_id, allow_inactive_user);
    } catch (error) {
      this.setState({
        isRetrying: false,
      });
      console.log('Authentication Error:', error); //eslint-disable-line
      this.retryCount += 1;
      if (this.retryCount >= 3) {
        this.setState({
          retryFailed: true,
          failureSource: 'AUTHENTICATION',
        });
        throw error;
      }
      return this.authenticateRequest();
    }
  }

  async authenticate() {
    const {
      history,
      setAuthenticationToken,
      setAuthenticationLegacyToken,
      setAuthenticationStatus,
      setAuthenticatedAccount,
      setLicenseeAccount,
      token: cookieToken,
      query,
    } = this.props;

    const {
      redirect_to,
      pk_license,
      cycle,
      is_doh_user: isDohUser = '',
      checkoutId = '',
      actionFrom = '',
      upgradeTo = '',
    } = query;

    if (!cookieToken) {
      window.location.href = config.launchpadUrl;
      return;
    }

    let authenticationResponse;
    try {
      if (checkoutId) {
        await this.validateCheckout(checkoutId);
      }

      authenticationResponse = await this.authenticateRequest();
    } catch (error) {
      return;
    }

    const { token, legacyToken, authenticatedAccount, licenseeAccount } = authenticationResponse.data;

    const {
      associationUsersInfo: {
        isAssociationUser,
        associationToken = null,
        inAllowRegularLogin = true,
        associationCode,
        associationReturnURL,
      },
    } = licenseeAccount;

    if (isAssociationUser && !associationToken && !inAllowRegularLogin) {
      this.associationCode = associationCode;
      this.associationReturnURL = associationReturnURL || config.supportData.url;
      this.setState({ shouldDenyAccess: true });
      return;
    }

    const { isBetaAvailable, subscriptions = [], type: subsType } = licenseeAccount;
    const states = {};

    licenseeAccount.licenses.forEach((license) => {
      const { profession } = license;
      const { state } = profession.board;
      const stateCode = state.code;

      if (!states[stateCode]) {
        states[stateCode] = { ...state, professions: [profession] };
      } else {
        if (!states[stateCode].professions.some((p) => p.id === profession.id)) {
          states[stateCode].professions.push(profession);
        }
      }
    });

    licenseeAccount.states = states;

    setAuthenticationToken(token);
    setAuthenticationLegacyToken(legacyToken);
    setAuthenticationStatus('authenticated');
    setAuthenticatedAccount(authenticatedAccount);
    setLicenseeAccount({
      ...licenseeAccount,
      isDohUser,
    });

    const licensesAccount = licenseeAccount.licenses;

    let selectedLicense;
    if (pk_license) {
      selectedLicense = licensesAccount.find((l) => l.id === parseInt(pk_license));
    }
    const { id: licenseId, currentCycleId } = selectedLicense ? selectedLicense : licensesAccount[0];
    const dohUserWelcome = 'showDohUserWelcome';
    const showWelcome = utils.getSessionItem(dohUserWelcome);

    if (isDohUser && showWelcome === null) {
      utils.setSessionItem(dohUserWelcome, 'true');
    }

    utils.setSessionItem('isAdHidden', 'false');
    utils.setSessionItem('isBannerHidden', 'false');
    utils.removeUseLocalStorage('toggleIdSelected');
    utils.removeUseLocalStorage('toggleChanged');
    utils.setSessionItem('selectedTab', '');

    let subscriptionType = 'BAS';

    if (subscriptions.length > 0) {
      subscriptionType = subscriptions[0].type;
    }
    let isEmployeeCCH = false;
    if (subscriptionType === 'BAS' && subsType === 'EMPLOYEE') {
      subscriptionType = 'EMP';
      isEmployeeCCH = true;
    }

    if (subsType === 'AUDIT') {
      subscriptionType = 'AAA';
    }

    if (subsType === 'DISCIPLINARY') {
      subscriptionType = 'DAA';
    }

    if (isEmployeeCCH) {
      const {
        user: { id: userId },
        autoRenewSettings: { customerId } = {},
      } = licenseeAccount;
      const [firstLicense] = licensesAccount;
      const { ownerId } = firstLicense;
      await updateTalonOneProfile(userId, { ownerId, customerId });
    }

    if (isBetaAvailable) {
      switch (redirect_to) {
        case 'transcript': {
          const queryParams = {};
          queryParams.cycle = cycle || currentCycleId;
          history.replace({
            pathname: `/license/${licenseId}/transcript`,
            search: queryString.stringify(queryParams),
          });
          break;
        }
        case 'course-history': {
          history.replace({ pathname: `/license/${licenseId}/overview` });
          break;
        }
        case 'my-learning/in-progress':
        case 'dashboard':
        case 'reported-exemptions':
        case 'inbox': {
          history.replace({ pathname: `/${redirect_to}` });
          break;
        }
        case 'upgrade': {
          history.replace({
            pathname: `/${redirect_to}`,
            state: { comeFrom: actionFrom },
          });
          break;
        }
        case 'upgrade-payment': {
          history.replace({
            pathname: `/upgrade`,
            state: { upgradeTo },
          });
          break;
        }
        case 'profile':
        case 'subscription':
        case 'payment-history': {
          history.replace({ pathname: `/settings/${redirect_to}` });
          break;
        }
        case 'overview': {
          const queryParams = {};
          queryParams.cycle = cycle || currentCycleId;
          history.replace({
            pathname: `/license/${licenseId}/overview`,
            search: queryString.stringify(queryParams),
          });
          break;
        }
        case 'reactivate-subscription': {
          history.replace({ pathname: `/reactivate-subscription` });
          break;
        }
        default:
          history.replace({ pathname: `/` });
          break;
      }
    } else {
      let extraParams = '';
      if (!['EMP', 'AAA', 'DAA'].includes(subscriptionType)) {
        extraParams = '?returnToClassic=true';
      }
      utils.redirectTo(`${config.legacyDashboardUrl(subscriptionType)}${extraParams}`);
    }
    // Delete all cookies that are not from this environment
    const cookiesToDelete = config.licenseesCookies.filter(
      (cookieName) => ![config.licenseeAuthInfoCookie, config.licenseeTokenCookie].includes(cookieName)
    );
    cookiesToDelete.forEach((cookie) => {
      utils.deleteCookie(cookie, 'cebroker.com');
    });

    const licensesLimitForCookie = parseInt(REACT_APP_LICENSES_LIMIT, 10);

    if (licenseeAccount.licenses.length <= licensesLimitForCookie) {
      const minifiedAuthInfo = utils.licenseeAuthInfoMinifier(authenticationResponse.data);
      const encodedAuthInfo = utils.convertObjectToHash(minifiedAuthInfo);
      utils.setCookie({
        name: config.licenseeAuthInfoCookie,
        value: encodedAuthInfo,
        domain: 'cebroker.com',
      });

      const encodedLicenseeToken = utils.convertObjectToHash(token);
      utils.setCookie({
        name: config.licenseeTokenCookie,
        value: encodedLicenseeToken,
        domain: 'cebroker.com',
      });
    } else {
      utils.deleteCookie(config.licenseeAuthInfoCookie, 'cebroker.com');
      utils.deleteCookie(config.licenseeTokenCookie, 'cebroker.com');
    }
  }

  validateCheckout = async (checkoutId, limit = 1) => {
    const maxLimit = 60;
    try {
      await asyncTimeout();

      const { data: checkout = {} } = await getCheckout(checkoutId);
      const { chargeId } = checkout;
      const checkoutSubscriptionData = utils.mapGTMCheckoutDataFromShoppingCart(checkout);
      const isGTMEventDubplicate = utils.validateExistingGTMEvent({ eventName: GTM_EVENTS.PURCHASE, extraFieldName: 'transaction_id', extraFieldValue: chargeId });
      if (!isGTMEventDubplicate && chargeId) {
        utils.registerEventGTM({
          event: GTM_EVENTS.PURCHASE,
          data: checkoutSubscriptionData,
        });
      }

      if (checkout.status === 'PENDING' && limit < maxLimit) {
        let newLimit = limit;
        return await this.validateCheckout(checkoutId, ++newLimit);
      }

      if (checkout.status === 'PENDING' && limit === maxLimit) {
        throw new Error('Checkout validation retry limit reached');
      }

      this.sendGTMEvent(checkout);
    } catch (error) {
      if (limit === 1) {
        // eslint-disable-next-line no-console
        console.log('Error trying to get checkoutId', error);
        this.setState({
          retryFailed: true,
          isRetrying: false,
          failureSource: 'SHOPPING_CART_CHECKOUT_VALIDATION',
        });
      }
      throw error;
    }
  };

  sendGTMEvent(checkout) {
    try {
      if (REACT_APP_ENABLE_GOOGLE_TAG_MANAGER === 'true') {
        const ecommerce = coursePurchaseEvent(checkout);
        utils.registerEventGTM({
          event: GTM_EVENTS.COURSE_PURCHASES,
          data: {
            ecommerce,
          },
        });
      }
    } catch (error) {
      console.log('Error sending cart purchases GTM Event for checkoutId', error);
    }
  }

  handleClickRetry = () => {
    this.setState({ isRetrying: true });
    return this.authenticate();
  };

  handleRedirect = () => {
    utils.deleteCookie(config.secureCookie, 'cebroker.com');
    utils.deleteCookie(config.licenseeTokenCookie, 'cebroker.com');
    utils.deleteCookie(config.licenseeAuthInfoCookie, 'cebroker.com');
    window.location.href = this.associationReturnURL;
  };

  getExtraContent = () => {
    const { isRetrying, retryFailed, shouldDenyAccess, failureSource } = this.state;
    const {
      query: { checkoutId },
    } = this.props;
    const fromShoppingCartContent = (
      <div className="retry-content">
        <p className="text-color">Please hold tight as we work to update your account with your purchase(s).</p>
        <p className="text-color">Do not leave this page. You will automatically be redirected back to your account.</p>
      </div>
    );
    const errorContent = (
      <div className="retry-content">
        {failureSourceMessageBody[failureSource]}
        <Button style={{ marginTop: '10px' }} loading={isRetrying} onClick={this.handleClickRetry}>
          Retry
        </Button>
      </div>
    );

    const denyAccessContent = (
      <div className="deny-access-content">
        <h2 className="text-color">Your CE Broker access is managed by an Association</h2>
        <p className="text-color">Access your account from the {this.associationCode} site</p>
      </div>
    );

    // Errors are the most relevant.
    if (retryFailed) return errorContent;

    if (shouldDenyAccess) return denyAccessContent;

    // Special case for providing more context to the user when coming from the shopping cart since it could take more time on this page.
    if (checkoutId) return fromShoppingCartContent;
  };

  render() {
    const { shouldDenyAccess } = this.state;
    const extraContent = this.getExtraContent();

    if (shouldDenyAccess) {
      setTimeout(() => {
        this.handleRedirect();
      }, 5000);
    }
    return (
      <div>
        <LoadingPage extraContent={extraContent} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  token: PropTypes.string,
  query: PropTypes.object,
  setAuthenticationStatus: PropTypes.func,
  setAuthenticationToken: PropTypes.func,
  setAuthenticationLegacyToken: PropTypes.func,
  setAuthenticatedAccount: PropTypes.func,
  history: PropTypes.object,
  setLicenseeAccount: PropTypes.func,
};

export default LoginPage;
