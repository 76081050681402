import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Loadable from 'react-loadable';
import htmlParser from 'html-react-parser';
import { Route, Switch, Redirect } from 'react-router-dom';

import config from '../../config';
import './App.css';
import DohReturnLink from '../DohReturnLink';
import Toaster from '../../containers/Toaster';
import SettingsPageLoading from '../SettingsPage/SettingsPageLoading';
import axios from 'axios';
import utils from '../../utils';

import HelpButton from 'emerald-ui/lib/HelpButton';
import OverviewPage from '../../containers/OverviewPage';
import LoadingPage from '../LoadingPage';
import Navbar from '../Navbar';
import AuditTask from '../../containers/AuditTask';
import Banner from '../../components/Banner';

import { ApmRoute } from '@elastic/apm-rum-react';
import LogRocket from 'logrocket';
import initializePendo from '../../utils/pendo';

const AsyncSettingsPage = Loadable({
  loader: () => import('../../containers/SettingsPage'),
  loading: SettingsPageLoading,
});
const AsyncHeartcardPage = Loadable({
  loader: () => import('../HeartcardPage'),
  loading: LoadingPage,
});
const AsyncInboxPage = Loadable({
  loader: () => import('../InboxPage'),
  loading: LoadingPage,
});
const AsyncMyLearningPage = Loadable({
  loader: () => import('../../containers/MyLearningPage'),
  loading: LoadingPage,
});
const AsyncFreeTrial = Loadable({
  loader: () => import('../../containers/FreeTrial'),
  loading: LoadingPage,
});
const AsyncAuditPage = Loadable({
  loader: () => import('../../containers/AuditPage'),
  loading: LoadingPage,
});

const { REACT_APP_ENABLE_LOG_ROCKET, REACT_APP_ENABLE_HELP } = process.env;

const {
  legacyDashboardUrl,
  launchpadUrl,
  licenseeTokenCookie,
  licenseeAuthInfoCookie,
  helpWidgetUrl,
  getCourseSearchUrl,
} = config;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      isDohUserBetaTour: false,
      showBanner: false,
      banner: '',
    };
    this.handleBannerDismiss = this.handleBannerDismiss.bind(this);
    this.setBannerLocalStorage = this.setBannerLocalStorage.bind(this);
    this.getBannerLocalStorage = this.getBannerLocalStorage.bind(this);
  }

  componentDidMount() {
    const {
      getSubscriptionPrices,
      getSubscriptionPlans,
      firstProfession,
      isFTOAvailable,
      subscriptionTypeLabel,
      user,
      pendoLicenses,
      authenticatedAccount,
      autoRenewSettings,
    } = this.props;
    if (getSubscriptionPrices) {
      getSubscriptionPrices();
    }
    if (getSubscriptionPlans) {
      getSubscriptionPlans();
    }

    if (REACT_APP_ENABLE_LOG_ROCKET && user) {
      LogRocket.identify(user.id, {
        name: `${user.firstName} ${user.lastName}`,
      });
    }
    if (user) {
      initializePendo({
        licenses: pendoLicenses,
        ...user,
        ...(autoRenewSettings || {}),
        ...authenticatedAccount,
        profession: firstProfession,
        isFTOAvailable,
        subscriptionType: subscriptionTypeLabel,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { saveCartItemStatus, addNotification, user, loggedOnBehalf, clearStatusCart, totalItemsInCart } = this.props;
    const queryParams = {
      redirect_to: 'cart',
    };
    if (loggedOnBehalf) {
      queryParams.userId = user.id;
    }

    if (prevProps.saveCartItemStatus === 'loading' && saveCartItemStatus === 'failed') {
      utils.showToastNotification({
        message: 'Error adding the course, please try again later.',
        actionText: 'CLOSE',
        addNotification,
      });
      clearStatusCart();
      return;
    }

    if (saveCartItemStatus === 'loaded' && totalItemsInCart > prevProps.totalItemsInCart) {
      utils.showToastNotification({
        message: 'Item added to cart',
        actionText: 'VIEW CART',
        addNotification,
        onActionClick: () => {
          utils.redirectTo(getCourseSearchUrl(queryParams, false));
        },
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { banner } = nextProps;
    const { showBanner } = banner;
    if (showBanner) {
      this.setState({ banner, showBanner });
    }
  }

  handleBannerDismiss() {
    const { changeVisibilyBanner } = this.props;
    changeVisibilyBanner({ showBanner: false });
    this.setState({ showBanner: false }, () => {
      this.setBannerLocalStorage({ ...this.state.banner, showBanner: false });
    });
  }

  setBannerLocalStorage(banner) {
    window.localStorage.setItem(utils.cebBanner, JSON.stringify(banner));
  }

  getBannerLocalStorage() {
    return window.localStorage.getItem(utils.cebBanner);
  }

  UNSAFE_componentWillMount() {
    const {
      authenticationStatus,
      secureToken,
      logout,
      isDohUser,
      fetchUserFlag,
      fetchCart,
      fetchNowCourses,
      user,
      userProfessions,
    } = this.props;
    if (authenticationStatus === 'unauthenticated') {
      window.location.replace(launchpadUrl);
      this.setState({ render: false });
      return false;
    }
    if (authenticationStatus === 'authenticated' && !secureToken) {
      if (utils.getCookie(licenseeTokenCookie) || utils.getCookie(licenseeAuthInfoCookie)) {
        utils.deleteCookie(licenseeTokenCookie, 'cebroker.com');
        utils.deleteCookie(licenseeAuthInfoCookie, 'cebroker.com');
      }
      this.setState({ render: false });
      logout();
    }
    fetchUserFlag('seeCheckoutPage');
    fetchUserFlag('notifyCoursescriptionDowngrade');
    fetchCart();
    fetchNowCourses({ courseStatus: 'progress', userId: user.id, limit: 0, professionIds: userProfessions });
    this.setState({ isDohUserBetaTour: isDohUser !== '' });
  }

  handleBetaTourStartDoh = () => {
    this.setState({ isDohUserBetaTour: false });
  };

  handleCebClassicClick = async () => {
    this.setState({ loadingPage: true });
    const { licenseesApiInternal } = config;
    const { user, subscriptionType, token, loggedOnBehalf } = this.props;

    if (loggedOnBehalf) {
      return utils.redirectTo(`${legacyDashboardUrl(subscriptionType)}?classicOnBehalf=true`);
    }
    await axios.request({
      url: `${licenseesApiInternal}/users/${user.id}/beta`,
      method: 'put',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        isBeta: false,
      },
    });
    await utils.delay(3000);
    utils.redirectTo(`${legacyDashboardUrl(subscriptionType)}?returnToClassic=true`);
  };

  render() {
    const { loadingPage, showBanner, banner = {} } = this.state;
    const { theme: bannerTheme, title: bannerTitle, content: bannerContent } = banner;
    if (loadingPage) {
      return <LoadingPage />;
    }

    const {
      history,
      user,
      subscriptionType,
      isFTOAvailable,
      loggedOnBehalf,
      firstProfession,
      isDohUser,
      location,
      authenticationStatus,
      showBrowseCourseLink,
      logout,
      totalNotifications,
      autoRenewSettings,
      lastSubscription,
      associationUsersInfo,
      isPaidByBoard,
      isWyoming,
      professionBrandColor,
      defaultLicenseSelected,
      currentCycleId,
      notification,
      fetchBanner,
      auditTasks,
      canSeeDiscover,
      totalItemsInCart,
      subscriptionCode,
    } = this.props;

    const { pathname } = location;
    const isLicenseOnStorage = utils.getUseLocalStorage('toggleIdSelected');

    const { firstShow, statusRequest } = auditTasks;

    const shouldHideBanner = ['loading', 'failed'].includes(statusRequest);

    const hasBanner = showBanner && shouldHideBanner && firstShow;

    if (!this.state.render || authenticationStatus === 'unauthenticated') {
      return null;
    }

    const licenseSelectedRedirect =
      isLicenseOnStorage && isLicenseOnStorage !== 'undefined' ? isLicenseOnStorage : defaultLicenseSelected;

    const isEmployee = subscriptionType === 'EMP';

    return (
      <div className="App">
        <Banner theme={bannerTheme} show={showBanner} onDismiss={this.handleBannerDismiss} fetchBanner={fetchBanner}>
          {bannerTitle && htmlParser(bannerTitle)}
          {bannerContent && htmlParser(bannerContent)}
        </Banner>
        <Navbar
          {...{
            history,
            user,
            subscriptionType: subscriptionCode,
            isFTOAvailable,
            loggedOnBehalf,
            firstProfession,
            autoRenewSettings,
            lastSubscription,
            showBrowseCourseLink,
            logout,
            pathname,
            onClickCebClassic: this.handleCebClassicClick,
            totalNotifications,
            associationUsersInfo,
            isPaidByBoard,
            isWyoming,
            professionBrandColor,
            hasBanner: showBanner,
            canSeeDiscover,
            totalItemsInCart,
          }}
        />
        {!isEmployee && <AuditTask />}
        {isDohUser && <DohReturnLink isDohUser={isDohUser} betaTourStartHandle={this.handleBetaTourStartDoh} />}
        <div
          className={classnames({
            'app-has-banner': hasBanner,
          })}
        >
          <Switch>
            <ApmRoute path="/license/:pkLicense" component={OverviewPage} />
            <Route
              path="/settings"
              render={(props) => {
                return <AsyncSettingsPage {...props} professionBrandColor={professionBrandColor} />;
              }}
            />
            <Route path="/free-trial" component={AsyncFreeTrial} />
            <Route path="/inbox" component={AsyncInboxPage} />
            <Route path="/my-learning" component={AsyncMyLearningPage} />
            <Route path="/heartcard" component={AsyncHeartcardPage} />
            <Route path="/audit-access/:pkLicense" component={AsyncAuditPage} />
            <Redirect path="*" to={`/license/${licenseSelectedRedirect}/overview?cycle=${currentCycleId}`} />
          </Switch>
        </div>
        <Toaster notifications={notification.id ? [notification] : []} />
        {REACT_APP_ENABLE_HELP && JSON.parse(REACT_APP_ENABLE_HELP) && (
          <HelpButton icon="help_outline" color="" onClick={() => utils.redirectTo(helpWidgetUrl, true)} fixed>
            Help
          </HelpButton>
        )}
      </div>
    );
  }
}

App.propTypes = {
  authenticationStatus: PropTypes.string,
  firstProfession: PropTypes.object,
  user: PropTypes.object,
  authenticatedAccount: PropTypes.object,
  subscriptionType: PropTypes.string,
  subscriptionTypeLabel: PropTypes.string,
  isFTOAvailable: PropTypes.bool,
  loggedOnBehalf: PropTypes.bool,
  token: PropTypes.string,
  history: PropTypes.object,
  secureToken: PropTypes.string,
  isDohUser: PropTypes.string,
  location: PropTypes.object,
  showBrowseCourseLink: PropTypes.bool,
  logout: PropTypes.func,
  totalNotifications: PropTypes.number,
  autoRenewSettings: PropTypes.object,
  lastSubscription: PropTypes.object,
  associationUsersInfo: PropTypes.object,
  isPaidByBoard: PropTypes.bool,
  isWyoming: PropTypes.bool,
  professionBrandColor: PropTypes.string,
  defaultLicenseSelected: PropTypes.number,
  currentCycleId: PropTypes.number,
  notification: PropTypes.object,
  fetchBanner: PropTypes.func,
  banner: PropTypes.object,
  changeVisibilyBanner: PropTypes.func,
  auditTasks: PropTypes.object,
  canSeeDiscover: PropTypes.bool,
  fetchUserFlag: PropTypes.func,
  getSubscriptionPrices: PropTypes.func,
  getSubscriptionPlans: PropTypes.func,
  fetchCart: PropTypes.func,
  pendoLicenses: PropTypes.array,
  fetchNowCourses: PropTypes.func,
  saveCartItemStatus: PropTypes.string,
  addNotification: PropTypes.func,
  clearStatusCart: PropTypes.func,
  totalItemsInCart: PropTypes.number,
  userProfessions: PropTypes.string,
};

App.defaultProps = {
  user: {},
  authenticatedAccount: {},
  history: {},
  isDohUser: '',
  location: {},
  notification: {},
  auditTasks: {},
  fetchUserFlag: () => {},
  fetchCart: () => {},
  fetchNowCourses: () => {},
  userProfessions: '',
};

export default App;
